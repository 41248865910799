// import font
@import "ui-kit--sm-meet/font-utils";
@import "reset-css";

$font-path: '../fonts/';
@include font-face("Mulish", "#{$font-path}mulish/Mulish-Regular", 400, inherit, eot woff2 woff ttf otf svg);
@include font-face("Mulish", "#{$font-path}mulish/Mulish-Bold",    600, inherit, eot woff2 woff ttf otf svg);
@include font-face("Mulish", "#{$font-path}mulish/Mulish-Medium",  500, inherit, eot woff2 woff ttf otf svg);

@include font-face("NotoSans", "#{$font-path}noto-sans/NotoSans-Regular",      400, inherit, eot woff2 woff ttf otf svg);
@include font-face("NotoSans", "#{$font-path}noto-sans/NotoSans-Light",        300, inherit, eot woff2 woff ttf otf svg);
@include font-face("NotoSans", "#{$font-path}noto-sans/NotoSans-SemiBold", 500, inherit, eot woff2 woff ttf otf svg);
@include font-face("NotoSans", "#{$font-path}noto-sans/NotoSans-Bold",     600, inherit, eot woff2 woff ttf otf svg);
@import "ui-kit--sm-meet/font-awesome-6/fontawesome"; // v6.6.0
@import "ui-kit--sm-meet/vars";
@import "inc/toggle-switch";
@import "ui-kit--sm-meet/ui-kit";


.page--404{
    min-height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;

    &__header,
    &__footer{
        height: 60px;
        padding: 2px 20px;
    }

    &__header{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        text-align: left;
        box-shadow: 0px 0px 2px 2px #f1f6ff;
        width: 100%;

        &-logo{
            display: inline-block;
            text-decoration: none;

            svg{
                height: 38px;
            }
        }

        &.header--invisible{
            opacity: 0;
            user-select: none;
            pointer-events: none;
        }
    }

    &__content{
        width: 100%;
        max-width: 400px;
        margin: 0 auto 35px auto;
        padding: 25px 20px;
        text-align: center;

        .title--h2{
            font-weight: bold;
            margin-bottom: 12px;
            @include break-min($tab){
                font-size: 40px;
            }
        }

        .p{

        }

        .btn-bar{
            padding-top: 25px;
        }
    }
    &__picture{
        margin-bottom: 20px;
        display: block;
        text-align: center;

        img{
            max-width: 100%;
            height: auto;
        }
    }
}